import React from "react";
import ListItem from "./ListItem";

export default function ({item}) {
        return (
            <section className="card__section">
                <h3 className="card-section__heading">{item.heading}</h3>
                <ul className="card-section__list">
                    <ListItem content={item.content}/>
                </ul>
            </section>
        )
}