import React, {useEffect} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation
} from "react-router-dom";
import Navigation from "./Navigation/Navigation";
import Home from "./Home/HomePage";
import Projects from "./Projects/Projects";
import AboutMe from "./AboutMe/AboutMe"
import "../style/style.scss";
import Api from "../Api";

/**
 * @return {null}
 */
function ScrollToTop() {
    const {pathname} = useLocation();
    useEffect(() => {
        window.scrollTo(0,0)
    }, [pathname]);

    return null;
}

function App() {
    const data = Api("get");

    return (
        <Router className="App" id="top">
            <ScrollToTop/>
            <Navigation/>
            <Switch>
                <Route path="/" exact component={() => <Home about={data.about} projects={data.projects}/>}/>
                <Route path="/projects" component={() => <Projects projects={data.projects} filters={data.filters}/>}/>
                <Route path="/aboutme" component={() => <AboutMe data={data.about}/>}/>
            </Switch>
            <footer className="footer row">
                <div className="footer__tools col-m-12 col-4">
                    <a className="footer-tools__link" href={"#top"}>Bring me back to top</a>
                </div>
                <div className="footer__links col-m-12 col-4">
                    <Link className="footer-links__link" to="/">Home</Link>
                    <Link className="footer-links__link" to="/projects">Projects</Link>
                    <Link className="footer-links__link" to="/aboutme">About me</Link>
                </div>
                <div className="footer__logo col-m-12  col-4">
                    <Link className="footer-logo" to="/">
                        <img className="footer-logo__logo" src="https://www.zettrex.no/media/images/logo-white.png" alt="My logo"/>
                    </Link>
                </div>
            </footer>
        </Router>
    );
}

export default App;