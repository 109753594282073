import {useState} from "react";
import json from "./data"; //can not get this to work with fetch for some reason...

export default function (task, input) {
    const [data, setData] = useState();
    if (/set/i.test(task)) {
        setData({
            ...data,
            input
        });
        return data;
    } else if (/get/i.test(task)) {
        if (!data) {
            setData({
                ...json,
                projects: {
                    original: json.projects,
                    operator: json.projects
                }
            })
        }
        return data;
    }
}