import React from "react";
import Contact from "../Contact/Contact";
import profilePicture from "../../media/images/ProfilePic2.png";
import SkillItem from "./components/SkillItem";

export default function ({data}) {
    return (
        <div className="page about">
            <main className="about__content">
                <h1 className="about-content__heading page__heading">ABOUT ME</h1>
                <img className="about-content__img" src={profilePicture} alt="Christian Gunvaldsen"/>
                <div className="about-content__wrapper row">
                    <div className="about-content__primary about-content__body col-d-6">
                        <section className="about-primary__whoAmI section">
                            <h3 className="about-whoAmI__heading page__heading--inverted">WHO AM I?</h3>
                            <p className="about-whoAmI__1 about-whoAmI__paragraph">
                                My name is Christian Gunvaldsen, I come from Stavanger in Norway. Previously I worked as a car mechanic for 5 years. My two big passions are cars and technology, where I love exploring and work with technology. And in the later years started gaining a passion for coding and general development.
                            </p>
                            <p className="about-whoAmI__2 about-whoAmI__paragraph">
                                I started getting in to coding by starting working on creating and modifying mods in games I played. where most of the mods where scripted in LUA. shortly after this I found out that this is more what I want to do as a job. and started gaining an education through wards my goal as a full-stack developer.
                            </p>
                            <p className="about-whoAmI__3 about-whoAmI__paragraph">
                                I have always had a interest in creating logo's and background images for quite some time, although I'm still quite new and unexperienced in this. its always been a big passion to do.
                            </p>
                            <p className="about-whoAmI__4 about-whoAmI__paragraph">
                                My biggest enjoyment in Development is starting to put stuff together and start figuring out how to put stuff from idea to reality.
                            </p>
                        </section>
                    </div>
                    <div className="about-content__secondary col-d-6 about-content__body">
                        <section className="about-secondary__education section">
                            <h3 className="about-education__heading page__heading--inverted">EDUCATION</h3>
                            <div className="about-education__content">
                                <span className="education-content__type">
                                    2011-2015 - Car mechanic
                                </span>
                                <span className="education-content__place">
                                    1 year Sola VGS, 1 year Randaberg VGS, 2 year Rogaland fagopplering/Hegre Auto.
                                    After i got the certificate I continued working at Hegre Auto for a further 3 years.
                                </span>
                            </div>
                            <div className="about-education__content">
                                <span className="education-content__type">
                                    2018-2020 - Frontend-Developer
                                </span>
                                <span className="education-content__place">
                                    Noroff School of Technology and media
                                </span>
                            </div>
                        </section>
                        <section className="about-secondary__skills section">
                            <h3 className="about-skills__heading page__heading--inverted">SKILLS</h3>
                            <dl className="about-skills__chart">
                                {data.content.skills.map(skill => <SkillItem key={skill.key} item={skill}/>)}
                            </dl>
                        </section>
                    </div>
                </div>
            </main>
            <Contact/>
        </div>
    )
}