import React from "react";

export default function ({tags, odd}) {
    if (odd) {
        return (
            <ul className="projects-display__tags projects-tags">
                {tags.map(tag => {
                    return <li className="projects-tags__item--odd" key={tag.key}>{tag.tag}</li>
                })}
            </ul>
        )
    } else {
        return (
            <ul className="projects-display__tags projects-tags">
                {tags.map(tag => {
                    return <li className="projects-tags__item--even" key={tag.key}>{tag.tag}</li>
                })}
            </ul>
        )
    }
}