import React from "react";
import ProjectItem from "./components/ProjectItem/ProjectItem";

export default function ({projects}) {
    return (
        <ul className="projects-list">
            {projects.map((project, i) => {
                if ((i+1) % 2 !== 0) {
                    return <ProjectItem project={project} odd={true} key={project.key}/>
                } else {
                    return <ProjectItem project={project} odd={false} key={project.key}/>
                }
            })}
        </ul>
    )
}