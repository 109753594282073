import React from "react";

export default function ({item}) {
    if (item.active) {
        return (
            <div className="projects-filter__item">
                <label className="filter-item">
                    <input name={item.content} className="filter-item__toggle" value={JSON.stringify(item)} type="checkbox"/>
                    <span className={`filter-item filter-item__${item.identifier} filter-item--active`}>
                        <i className="far fa-check-square"/> {item.content}
                    </span>
                </label>
            </div>
        )
    } else {
        return ( //add click function
            <div className="projects-filter__item">
                <label className="filter-item">
                    <input name={item.content} className="filter-item__toggle" value={JSON.stringify(item)} type="checkbox"/>
                    <span className={`filter-item filter-item__${item.identifier}`}>
                        <i className="far fa-square"/> {item.content}
                    </span>
                </label>
            </div>
        )
    }
}