import React from "react";

export default function ({tags, show}) {
    console.log(show);
    if (show) {
        return (
            <ul className="home-display__tags home-tags">
                {tags.map(tag => {
                    return <li className="home-tags__item" key={tag.key}>{tag.tag}</li>
                })}
            </ul>
        )
    } else {
        return (
            <ul className="home-display__tags home-tags home-tags--hidden">
                {tags.map(tag => {
                    return <li className="home-tags__item" key={tag.key}>{tag.tag}</li>
                })}
            </ul>
        )
    }
}