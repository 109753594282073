import React from "react";

import FilterItem from "./component/FilterItem"

export default function ({filterF, filters}) {
    //console.log(filters);
    return (
        <aside className="projects__filter projects-filter col-d-3 col-12">
            <form className="projects-filter__form form" onChange={event => filterF(event)}>
                <div className="projects-filter__list form__section col-t-4">
                    <span className="projects-filter__type">Show</span>
                    {filters.featured.map(filter => <FilterItem key={filter.key} item={filter}/>)}
                </div>
                <div className="projects-filter__list form__section col-t-4">
                    <span className="projects-filter__type">Categories</span>
                    {filters.categories.map(filter => <FilterItem key={filter.key} item={filter}/>)}
                </div>
                <div className="projects-filter__list form__section col-t-4">
                    <span className="projects-filter__type">Year</span>
                    {filters.date.map(filter => <FilterItem key={filter.key} item={filter}/>)}
                </div>
            </form>
        </aside>
    )
}