import React from "react";

export default function ({content}) {
    if (content) {
        return content.map(item =>
            <li key={item.key} className={"list__item"}>{item.skill}</li>
        )
    } else {
        return null
    }
}