import React, {useEffect, useState} from "react";
import ProjectFilter from "./components/ProjectFilter/ProjectFilter";
import ProjectList from "./components/ProjectList/ProjectList";

export default function ({projects, filters}) {
    const [data, setData] = useState({
        projects: projects,
        filters: filters,
    });

    function _filterProjects(event) {
        event.persist();
        const target = JSON.parse(event.target.value);

        const updatedFilters = updateFilters();
        function updateFilters () {
            if (target.type === "featured") {
                let featured = false;
                return {
                    ...data.filters,
                    featured : data.filters.featured.map(display => {
                        if (target.key === "5cc3308f-09a8-4e7d-9488-ec09df3634f5") {
                            if (display.key === target.key) {
                                return {
                                    ...display,
                                    active: true
                                }
                            } else {
                                return {
                                    ...display,
                                    active: false
                                }
                            }
                        } else {
                            if (display.key === target.key) {
                                console.log("sets featured to: ", (display.identifier === "fp"));
                                featured = (display.identifier === "fp")
                            }
                            return {
                                ...display,
                                active: !display.active
                            };
                        }
                    }),
                    activeFilters: {
                        ...data.filters.activeFilters,
                        featured: featured
                    },
                }
            } else if (target.type === "category") {
                let categories = [];
                return {
                    ...data.filters,
                    categories: data.filters.categories.map(tag => {
                        //checks if the clicked checkbox is category all, and if all sets filters to be empty, and sets all
                        // to be active and all other filters to be unselected
                        if (target.key === "2e7052ca-8825-4b83-bb7b-21b6a67a7043") {
                            if (tag.key === target.key) {
                                console.log("we are inside all categories");
                                categories = [];
                                return {
                                    ...tag,
                                    active: true
                                }
                            } else {
                                return {
                                    ...tag,
                                    active: false
                                }
                            }
                        } else { //if not it will check if category is corresponding with clicked and adds the tags to
                            // active filter. and toggle the active on the category filter (returns item)
                            if (tag.key === target.key) {
                                if (tag.value) {
                                    console.log("we are in (else => tag === target)", "check: ",data.filters.activeFilters.categories, tag.value.map(tag => tag.tag));
                                    tag.value.forEach(tag => {
                                        if (data.filters.activeFilters.categories.includes(tag.tag)) {
                                            categories.splice(data.filters.activeFilters.categories.indexOf(tag.tag), 1);
                                        } else {
                                            //console.log("adding (", tag.tag, ") to categories");
                                            categories.push(tag.tag);
                                        }
                                        //console.log("categories: ", categories)
                                    });
                                    //console.log(temp_activeFilters.categories)
                                }
                                return {
                                    ...tag,
                                    active: !tag.active
                                }
                                //as there is filters active it checks if current mapping category is all and sets the
                                // active to be false so its unselected
                            } else if (tag.key === "2e7052ca-8825-4b83-bb7b-21b6a67a7043") {
                                return {
                                    ...tag,
                                    active: false
                                }
                            } else { //if its not the clicked or the all it returns it back in the current state it was before.
                                if (tag.active) {
                                    console.log("do we get here?");
                                    tag.value.forEach(tag => {
                                        categories.push(tag.tag);
                                    });
                                }
                                return tag
                            }
                        }
                    }),
                    activeFilters: {
                        ...data.filters.activeFilters,
                        categories: categories
                    }
                }
            } else if (target.type === "date") {
                let dates = [];
                return {
                    ...data.filters,
                    date: data.filters.date.map(date => {
                        console.log(target.value, date.value);
                        if (target.key === "390e0abf-aeae-43bb-bf71-00065529e105") {
                                if (date.key === target.key) {
                                    dates = [];
                                    return {
                                        ...date,
                                        active: true
                                    }
                                } else {
                                    return {
                                        ...date,
                                        active: false
                                    }
                                }

                        } else if (date.key === target.key && date.value) {
                            if (data.filters.activeFilters.date.includes(date.value)) {
                                dates.splice(data.filters.activeFilters.date.indexOf(date.value), 1)
                            } else {
                                dates.push(date.value);
                            }
                            return {
                                ...date,
                                active: !date.active
                            }
                        } else if (date.key === "390e0abf-aeae-43bb-bf71-00065529e105") {
                            return {
                                ...date,
                                active: false
                            }
                        } else {
                            if (date.active && date.value) {
                                dates.push(date.value);
                            }
                            return date
                        }
                    }),
                    activeFilters: {
                        ...data.filters.activeFilters,
                        date: dates
                    }
                }
            }
        }

        const filteredProjects = data.projects.original.filter(project => {
            let checkFeatured, checkCategories, checkDate = false;
            //console.log(temp_activeFilters.featured, temp_activeFilters.categories, temp_activeFilters.date);
            if (updatedFilters.activeFilters.featured) {
                checkFeatured = project.featured;
            } else {
                checkFeatured = true
            }
            if (updatedFilters.activeFilters.categories[0]) {
                updatedFilters.activeFilters.categories.forEach(cTag => {
                    project.tags.forEach((pTag) => {
                        if (pTag.tag === cTag) {
                            checkCategories = true;
                        }
                    });
                });
            } else {
                checkCategories = true;
            }
            if (updatedFilters.activeFilters.date[0]) {
                updatedFilters.activeFilters.date.forEach(date => {
                    if (project.year === date) {
                        checkDate = true;
                    }
                });
            } else {
                checkDate = true;
            }
            //console.log("filter check: (", checkFeatured, checkCategories, checkDate + ")");
            return (checkFeatured && checkCategories && checkDate)
        });
        //console.log("activeFilters: ", temp_activeFilters ,"filteredProjects", filteredProjects);

        setData({
            ...data,
            filters: updatedFilters,
            projects: {
                ...data.projects,
                operator: filteredProjects
            }
        });
    }
    useEffect(() => {
        console.log("active filters are: ", ["featured: ", data.filters.activeFilters.featured], ["categories: ", data.filters.activeFilters.categories], ["date", data.filters.activeFilters.date]);
        console.log("with useEffect", ["All: ", data.filters.categories[0].active], ["Html/Js", data.filters.categories[1].active], ["Wordpress", data.filters.categories[2].active])
    }, [data]);

    console.log("without useEffect", data.filters.activeFilters, data.filters.featured, data.projects.operator);

    return (
        <div className="page projects row">
            <ProjectFilter filterF={_filterProjects} filters={data.filters}/>
            <main className="projects__list col-d-9">
                <h1 className="projects__h1 page__heading">MY PROJECTS</h1>
                <ProjectList projects={data.projects.operator}/>
            </main>
        </div>
    )
}